
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import CompletePortfolio from '../components/completePortfolio'
import lang from '../data/es.json'

const ProjectsEsPage = ({ location, data }) => (
  <Layout
    contrast
    isProjects
    lang={lang}
    location={location}
    projectsData={data.allSitePage.nodes.map(node => node.context.projectData)}
    component={CompletePortfolio}
    logo={data.logo.sizes}
    images={data.allImageSharp.nodes.map(node => node.sizes)}
  />
)

export default ProjectsEsPage

export const pageQuery = graphql`
  query ProjectsEsQuery {
    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }

    allImageSharp(
      filter: { fluid: { originalName: { glob: "{smallProject-,bg}*.png" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }

    allSitePage(
      sort: { order: ASC, fields: context___projectData___index }
      filter: { component: { glob: "**/project.es.js" } }
    ) {
      nodes {
        context {
          projectData {
            name {
              fullName
              white
              blue
            }
            caption
            image
            path
          }
        }
      }
    }
  }
`
